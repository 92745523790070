var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{attrs:{"justify":"center"}},[_c('material-card',{attrs:{"width":"98%","icon":"mdi-cellphone-dock","color":"primary","title":"Producto","text":"Detalles del producto"}},[_c('v-data-table',{attrs:{"no-results-text":"No se encontraron resultados","hide-default-footer":"","headers":_vm.headers,"items":_vm.items,"items-per-page":50},scopedSlots:_vm._u([{key:"item.switch",fn:function(ref){
var index = ref.index;
var item = ref.item;
return [_c('span',{staticClass:"format-breaklines"},[_c('v-switch',{attrs:{"input-value":item.stock_status === 'instock',"loading":_vm.switchLoading.includes(item.id || item.idEcommerce)},on:{"change":function (val) { return _vm.changeItemStatus(val, item); }}})],1)]}},{key:"item.id",fn:function(ref){
var index = ref.index;
var item = ref.item;
return [_c('span',{staticClass:"format-breaklines"},[_vm._v(" "+_vm._s(index === 0 ? item.idEcommerce : item.id)+" ")])]}},{key:"item.title",fn:function(ref){
var index = ref.index;
var item = ref.item;
return [(index === 0)?_c('span',{staticClass:"format-breaklines"},[_vm._v(" "+_vm._s(item.name)+" ")]):_vm._e()]}},{key:"item.talla",fn:function(ref){
var index = ref.index;
var item = ref.item;
return [(index === 0)?_c('span',{staticClass:"format-breaklines"},[_vm._v(" "+_vm._s(item.attributesFormatted.talla ? item.attributesFormatted.talla.options.join(", ") : "")+" ")]):_c('span',{staticClass:"format-breaklines"},[_vm._v(" "+_vm._s(item.attributesFormatted.talla ? item.attributesFormatted.talla.option : "")+" ")])]}},{key:"item.color",fn:function(ref){
var index = ref.index;
var item = ref.item;
return [(index !== 0)?_c('span',{staticClass:"format-breaklines"},[_vm._v(" "+_vm._s(item.attributesFormatted.color ? item.attributesFormatted.color.option : "")+" ")]):_vm._e()]}},{key:"item.stock_quantity",fn:function(ref){
var item = ref.item;
return [(item.id)?_c('v-edit-dialog',{attrs:{"large":"","persistent":""},on:{"save":function($event){return _vm.handleStockSave(item)},"open":function($event){_vm.currentStock = item.stock_quantity}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('div',{staticClass:"mt-4 text-h6"},[_vm._v(" Modificar Stock ")]),_c('v-text-field',{ref:"stockTextEdit",attrs:{"label":"Edit","type":"number","min":"0","rules":_vm.stockRules,"single-line":"","autofocus":""},model:{value:(_vm.currentStock),callback:function ($$v) {_vm.currentStock=$$v},expression:"currentStock"}})]},proxy:true}],null,true)},[_c('div',[_vm._v(_vm._s(item.stock_quantity))])]):_vm._e()]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }